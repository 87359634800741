

























import { Component, Mixins } from 'vue-property-decorator'
import PasswordField from '@/modules/users/components/PasswordField.vue'
import FormMixin from '@/general/mixins/FormMixin'
import AuthenticationService from '@/general/services/AuthenticationService'

@Component({
  components: {
    PasswordField
  }
})
export default class FirstLogin extends Mixins(FormMixin, AuthenticationService) {
  // password = ''
  // repeatPassword = ''
  //
  // get firstLogin() {
  //   return false
  // }
  //
  // submit() {
  //   changePasswordUser(this.password).then((response: AxiosResponse) => {
  //     this.$store.commit('setToken', {
  //       token: response.headers['authorization'].substring(7),
  //       storage: sessionStorage.getItem('Authorization') ? sessionStorage : localStorage
  //     })
  //   })
  // }
}
