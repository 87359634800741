


















import { Component, Vue, Watch } from 'vue-property-decorator'
import { localize } from 'vee-validate'

@Component
export default class LanguageMenu extends Vue {
  protected locales = {
    bg: 'български',
    da: 'Dansk',
    de: 'Deutsch',
    es: 'Español',
    en: 'English',
    et: 'Eestlane',
    fi: 'Suomalainen',
    fr: 'Français',
    it: 'Italiano',
    lv: 'Latvietis',
    lt: 'Lietuvis',
    nl: 'Nederlands',
    no: 'Norsk',
    pl: 'Polskie',
    pt: 'Português',
    ru: 'русский',
    sv: 'Svenska',
    cs: 'Czech',
    ro: 'Romanian'
  }

  @Watch('$i18n.locale')
  setLocale(locale: string) {
    localize(locale)
    localStorage.setItem('locale', locale)
  }
}
